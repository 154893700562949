<template>
  <v-sheet class="py-5 px-10" rounded="10">
    <v-row class="align-center justify-space-between mb-5 mb-md-0">
      <v-col class="d-flex flex-wrap align-center order-1 order-md-0" cols="12" md="5">
        <div v-if="permission.new || permission.newForClient">
          <v-menu v-model="createType" v-if="withTypeSelect" offset-y>
            <template #activator="{on, attrs}">
              <ActionButton class="mr-10" v-bind="attrs" v-on="on">
                {{ $t('button.new') }}
              </ActionButton>
            </template>
            <v-list dense>
              <v-list-item v-for="type in typeItems" :key="type.value" @click="createNewHandler(type.value)">
                <v-list-item-title>
                  {{ type.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <ActionButton v-else class="mr-10" @click="createNewHandler(createType)">
            {{ $t('button.new') }}
          </ActionButton>
        </div>
        <v-icon v-if="isShowFilter" class="mr-10" @click="deleteAllFiltersValue">mdi-delete</v-icon>
        <v-menu
          v-model="isFilterMenu"
          offset-y
          :close-on-content-click="false"
          :close-on-click="closeOnClick"
          min-width="380"
          max-width="380"
        >
          <template #activator="{on, attrs}">
            <v-btn text v-bind="attrs" v-on="on">{{ $t('button.addFilter') }}</v-btn>
          </template>
          <v-list v-if="!filterItem">
            <template v-for="(item, i) in filterItems">
              <v-list-item v-if="showFilterType(item)" :key="'filter_' + i" @click="openFilterType(item)">
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <v-card v-else class="py-5 px-10">
            <div>
              <template v-if="filterItem.type === 'string'">
                <CustomInput v-model="filterValue" hide-details :placeholder="$t('placeholder.enterValue')" />
              </template>
              <template v-else-if="filterItem.type === 'select'">
                <CustomSelect
                  v-model="filterValue"
                  :items="filterItem.items"
                  hide-details
                  :placeholder="$t('placeholder.selectValue')"
                  :multiple="filterItem.multiple"
                >
                  <template #selection="{item, index}">
                    <div v-if="filterItem.multiple">
                      <span v-if="index === 0">
                        {{ item.text }}
                      </span>
                      <span v-if="index === 1" class="text-caption pl-2">
                        (+{{ filterValue.length - 1 }} {{ $t('label.others') }})
                      </span>
                    </div>
                    <div v-else>{{ item.text }}</div>
                  </template>
                  <template #item="{item, attrs, on}">
                    <v-list-item
                      v-if="filterItem.multiple"
                      #default="{active}"
                      v-bind="attrs"
                      dense
                      v-on="on"
                    >
                      <v-list-item-action>
                        <v-checkbox dense :ripple="false" :input-value="active" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item-content v-else>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-if="filterItem.isAction" #append-item>
                    <div class="d-flex justify-space-between my-5 px-3">
                      <v-btn text @click="cancelFilterMenu"> {{ $t('button.cancel') }} </v-btn>
                      <v-btn color="blue" text @click="applyFilterValue"> {{ $t('button.apply') }} </v-btn>
                    </div>
                  </template>
                </CustomSelect>
              </template>
              <template v-else-if="filterItem.type === 'checkbox'">
                <CustomCheckbox v-model="filterValue" hide-details class="mt-0" :label="filterItem.text" />
              </template>
              <template v-else-if="filterItem.type === 'server'">
                <CustomSelect
                  v-model="filterValue"
                  :loading="isServerLoad"
                  :items="userItems"
                  :placeholder="$t('placeholder.selectValue')"
                  hide-details
                />
              </template>
              <div class="d-flex justify-space-between mt-5">
                <v-btn text @click="cancelFilterMenu"> {{ $t('button.cancel') }} </v-btn>
                <v-btn color="blue" text @click="applyFilterValue"> {{ $t('button.apply') }} </v-btn>
              </div>
            </div>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" md="7" class="d-flex flex-wrap align-center justify-md-end order-0 order-md-1">
        <div class="mr-5">{{ $t('units.sort') }}:</div>
        <v-col class="d-flex align-center" sm="4" lg="3" xl="2">
          <CustomSelect
            v-model="sortBy"
            :placeholder="$t('label.params')"
            :items="sortItems"
            hide-details
            @change="setSortParams(sortBy, 'sortBy')"
          />
          <v-icon
            class="custom-transition ml-5"
            :class="{rotate: sortDesc === 'asc'}"
            size="28"
            @click="setSortParams(sortDesc, 'sortDesc')"
            >mdi-arrow-up</v-icon
          >
        </v-col>
      </v-col>
    </v-row>
    <v-slide-y-transition hide-on-leave>
      <v-row v-if="isShowFilter">
        <v-col cols="12" class="d-flex flex-wrap align-center">
          <div v-for="filter in selectedFilters" :key="filter.text" class="mb-3">
            <template v-if="filter.isDeep">
              <CustomChip
                v-for="child in filter.value"
                :key="child"
                color="grey"
                @click:close="deleteFilterValue(filter, child)"
              >
                {{ $t(filter.text) + ' = ' + child }}
              </CustomChip>
            </template>
            <template v-else>
              <CustomChip color="grey" @click:close="deleteFilterValue(filter)">
                {{ showChipValue(filter) }}
              </CustomChip>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-sheet>
</template>
<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'CreativeFilter',
  components: {},
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    sortItems: {
      type: Array,
      default: () => [],
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
    typeItems: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: Object,
      default: () => {},
    },
    withTypeSelect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortBy: null,
    sortDesc: null,
    isFilterMenu: false,
    closeOnClick: true,
    filterItem: null,
    filterValue: null,
    isServerLoad: false,
    createType: null,
  }),
  computed: {
    ...mapState({
      userItems: (state) => state.filter.userItems,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
  },
  methods: {
    ...mapActions({
      getUserItems: 'filter/GET_USER_ITEMS',
    }),
    ...mapMutations({}),
    getSortParams() {
      const {sort} = this.formData
      this.sortBy = sort.split(',')[0]
      this.sortDesc = sort.split(',')[1]
    },
    setSortParams(val, type) {
      if (type === 'sortDesc') {
        this.sortDesc = val === 'desc' ? 'asc' : 'desc'
      }
      const sort = this.sortBy + ',' + this.sortDesc
      this.$emit('sort', sort)
    },
    showFilterType(item) {
      switch (item.isRole) {
        case true:
          return this.isAdmin || this.isManager || this.isAdvertiserManager
        default:
          return true
      }
    },
    async openFilterType(item) {
      if (item.type === 'server') await this.loadFilterItems(item.name)
      const current = this.selectedFilters?.find((a) => a.name === item.name)
      if (current) this.filterValue = current.value
      this.filterItem = item
      this.closeOnClick = false
    },
    async loadFilterItems(name) {
      this.isServerLoad = true
      switch (name) {
        case 'userId':
          await this.getUserItems()
          break
        default:
          break
      }
      this.isServerLoad = false
    },
    cancelFilterMenu() {
      this.filterItem = null
      this.filterValue = null
      this.closeOnClick = true
    },
    applyFilterValue() {
      this.isFilterMenu = false
      this.$emit('addFilter', {filterValue: this.filterValue ?? false, filterItem: this.filterItem})
      setTimeout(() => this.cancelFilterMenu(), 300)
    },
    showChipValue(item) {
      const {type, value, text} = item
      switch (type) {
        case 'server':
          return this.$t(text) + ' = ' + this.userItems?.find((a) => a.value === value).text
        // case 'checkbox':
        //   return text + ' = ' + text
        default:
          return this.$t(text) + ' = ' + value
      }
    },
    deleteFilterValue(item, childValue = undefined) {
      this.$emit('deleteFilter', item, childValue)
    },
    deleteAllFiltersValue() {
      this.isFilterMenu = false
      this.$emit('deleteAllFilter')
      setTimeout(() => this.cancelFilterMenu(), 300)
    },
    createNewHandler(type) {
      this.$emit('new', type)
    },
  },
  mounted() {
    this.getSortParams()
  },
}
</script>
