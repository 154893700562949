<template>
  <div>
    <CreativeFilter
      :isShowFilter="isShowFilter"
      :selectedFilters="selectedFilters"
      :formData="formData"
      :sortItems="SORT_ITEMS"
      :filterItems="FILTER_ITEMS"
      :typeItems="TYPE_ITEMS"
      :permission="permission"
      withTypeSelect
      class="mb-15"
      @new="createNewHandler"
      @sort="loadSortItems"
      @addFilter="addFilterHandler"
      @deleteFilter="deleteFilterHandler"
      @deleteAllFilter="deleteAllFilterHandler"
    />

    <v-row v-if="loading">
      <v-col v-for="item in formData.size" :key="item.id" cols="12" sm="6" md="4" lg="3" xl="2" class="mb-20">
        <v-skeleton-loader class="mx-auto" type="card, list-item-three-line"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="!loading && items.length">
      <v-col v-for="item in items" :key="item.id" cols="12" sm="6" md="4" lg="3" xl="2" class="mb-20">
        <CreativeItem
          :item="item"
          :loading="loading"
          :permission="permission"
          :token="token"
          :basicUrl="basicUrl"
          @action="actionHandler"
        />
      </v-col>
    </v-row>
    <v-row v-else class="mt-30 mb-10">
      <v-col cols="12" class="text-center font-18 font-weight-regular">{{ $t('table.noData') }}</v-col>
    </v-row>

    <Pagination
      v-model="dataPagination.pageNumber"
      :totalPages="dataPagination.totalPages"
      :isShow="!!items.length"
      :disabled="loading"
      @input="setPage"
    />

    <CustomDialog v-model="isActionDialog" minHeight="250">
      <div class="text-center mt-15">
        <h3>{{ $t('dialog.deleteTitle') }}</h3>
        <ActionButton class="my-15" :loading="actionLoading" @click="deleteHandler">
          {{ $t('button.confirm') }}
        </ActionButton>
      </div>
    </CustomDialog>
  </div>
</template>
<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import CreativeFilter from '@/views/creatives/part/CreativeFilter'
import CreativeItem from '@/views/creatives/part/CreativeItem'
import Pagination from '@/components/pagination/Pagination'

import {SORT_ITEMS, FILTER_ITEMS, TYPE_ITEMS} from '@/helpers/variables/creatives'
import {setItem} from '@/helpers/persistantStorage'

export default {
  name: 'Creatives',
  components: {CreativeFilter, CreativeItem, Pagination},
  data: () => ({
    actionItem: null,
    isActionDialog: false,
    isShowFilter: false,
    selectedFilters: [],
    SORT_ITEMS,
    FILTER_ITEMS,
    TYPE_ITEMS,
  }),
  computed: {
    ...mapState({
      items: (state) => state.creative.items,
      formData: (state) => state.creative.formData,
      dataPagination: (state) => state.creative.dataPagination,
      loading: (state) => state.creative.loading,
      actionLoading: (state) => state.creative.actionLoading,
      isResetSortParams: (state) => state.creative.isResetSortParams,
      token: (state) => state.auth.token,
      basicUrl: (state) => state.auth.basicUrl,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      permission: 'creative/permission',
    }),
  },
  methods: {
    ...mapActions({
      getItems: 'creative/GET_ITEMS',
      deleteCreative: 'creative/DELETE_CREATIVE',
    }),
    ...mapMutations({
      resetFormData: 'creative/RESET_FORM_DATA',
      setPageNumber: 'creative/SET_PAGE_NUMBER',
      setSortParams: 'creative/SET_SORT_PARAMS',
    }),
    saveCreativeType(type) {
      setItem('nt_creative_type', type)
    },
    createNewHandler(type) {
      this.saveCreativeType(type)
      let name = ''
      switch (true) {
        case this.isAdmin:
          name = 'AdminNewCreative'
          break
        case this.isManager:
          name = 'ManagerNewCreative'
          break
        default:
          name = 'AdvertiserNewCreative'
      }
      this.$router.push({name: name})
    },
    async loadData(formData, isStart = false) {
      if (isStart) formData.page = 0
      this.selectedFilters.forEach((a) => {
        formData = Object.assign({[a.name]: a.value}, formData)
      })
      await this.getItems(formData)
    },
    async setPage(page) {
      await this.setPageNumber(page)
      await this.loadData(this.formData)
    },
    async loadSortItems(sort) {
      await this.setSortParams({sort})
      await this.loadData(this.formData)
    },
    addFilterHandler({filterValue, filterItem}) {
      const {type, name, text, isDeep} = filterItem
      const selectedObj = {
        text,
        name,
        type,
        isDeep,
        value: filterValue,
      }
      const isDuplicate = this.selectedFilters.some((a) => a.text === text)
      if (!isDuplicate) {
        this.isShowFilter = true
        this.selectedFilters.push(selectedObj)
      } else {
        const index = this.selectedFilters.findIndex((a) => a.text === text)
        Object.assign(this.selectedFilters[index], selectedObj)
      }
      this.loadData(this.formData, true)
    },
    deleteFilterHandler(filterItem, childValue) {
      const {text, isDeep} = filterItem
      if (isDeep) {
        filterItem.value = filterItem.value.filter((a) => a !== childValue)
        if (filterItem.value.length) {
          const index = this.selectedFilters.findIndex((b) => b.text === text)
          this.selectedFilters[index] = filterItem
        } else {
          this.selectedFilters = this.selectedFilters.filter((a) => a.text !== text)
        }
      } else {
        this.selectedFilters = this.selectedFilters.filter((a) => a.text !== text)
      }
      if (this.selectedFilters.length === 0) this.isShowFilter = false
      this.loadData(this.formData, true)
    },
    deleteAllFilterHandler() {
      this.selectedFilters = []
      this.isShowFilter = false
      this.loadData(this.formData, true)
    },
    clear() {
      this.resetFormData()
      this.setPage()
    },
    actionHandler({item, type}) {
      this.saveCreativeType(item.type)
      this.actionItem = item
      switch (type) {
        case 'details':
          this.setModerateView(false)
          this.goToDetails(item.id)
          break
        case 'delete':
          this.showActionDialog()
          break
        case 'moderated':
          this.setModerateView(true)
          this.goToDetails(item.id)
          break
        default:
          break
      }
    },
    showActionDialog() {
      this.isActionDialog = true
    },
    async deleteHandler() {
      const {id} = this.actionItem
      await this.deleteCreative(id)
      this.isActionDialog = false
      await this.loadData(this.formData)
    },
    goToDetails(id) {
      let name = ''
      switch (true) {
        case this.isAdmin:
          name = 'AdminCreativeDetail'
          break
        case this.isManager:
          name = 'ManagerCreativeDetail'
          break
        default:
          name = 'AdvertiserCreativeDetail'
      }
      this.$router.push({name: name, params: {id}})
    },
    setModerateView(value) {
      setItem('nt_creative_moderated', JSON.stringify(value))
    },
  },
  mounted() {
    this.loadData(this.formData)
    this.setModerateView(false)
  },
}
</script>
