<template>
  <v-sheet
    rounded="10"
    elevation="2"
    class="d-flex flex-column align-center justify-space-between overflow-hidden pa-10"
    height="100%"
  >
    <template v-if="media.type === 'VIDEO'">
      <video preload controls :width="mediaWidth" :height="mediaHeight">
        <source :src="media.src" type="video/mp4" />
      </video>
      <CreativeItemInfo :media="media" />
    </template>
    <div
      v-if="media.type === 'AUDIO'"
      class="d-flex flex-column align-center justify-space-between h--100 width-100 pt-10"
    >
      <audio controls :src="media.src"></audio>
      <CreativeItemInfo :media="media" />
    </div>
    <template v-if="media.type === 'NATIVE'">
      <p class="align-self-start">{{ media.title }}</p>
      <v-img :width="mediaWidth" :height="mediaHeight" contain :src="media.src" />
      <CreativeItemInfo :media="media" />
    </template>
    <template v-else-if="media.type === 'BANNER_IMG'">
      <v-img :width="mediaWidth" :height="mediaHeight" contain :src="media.src" />
      <CreativeItemInfo :media="media" />
    </template>
    <template v-else-if="media.type === 'BANNER_HTML'">
      <iframe frameborder="0" :width="mediaWidth" :height="mediaHeight" :src="media.src" />
      <CreativeItemInfo :media="media" />
    </template>
    <div class="text-center mt-5">
      <v-hover v-if="permission.remove" v-slot="{hover}" class="mx-5">
        <v-icon :color="hover ? 'red' : ''" size="20" @click="$emit('action', {item, type: 'delete'})">
          mdi-trash-can-outline
        </v-icon>
      </v-hover>
      <v-hover v-if="permission.read" v-slot="{hover}" class="mx-5">
        <v-icon :color="hover ? 'red' : ''" size="20" @click="$emit('action', {item, type: 'details'})">
          mdi-pencil
        </v-icon>
      </v-hover>
      <span v-if="isNotModerated" class="mx-5">
        <v-hover v-slot="{hover}" class="mr-10">
          <v-icon :color="hover ? 'red' : ''" size="20" @click="$emit('action', {item, type: 'moderated'})">
            mdi-credit-card-edit
          </v-icon>
        </v-hover>
        <TooltipEffect bottom color="red" :message="moderatedText">
          <template #activator="{on}">
            <v-icon size="20" color="red" v-on="on">mdi-information-outline</v-icon>
          </template>
        </TooltipEffect>
      </span>
    </div>
  </v-sheet>
</template>
<script>
import CreativeItemInfo from '@/views/creatives/part/CreativeItemInfo'

export default {
  name: 'CreativeItem',
  components: {CreativeItemInfo},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: Object,
      default: () => {},
    },
    token: {
      type: String,
      default: '',
    },
    basicUrl: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    mediaWidth: '100%',
    mediaHeight: '250',
    media: {},
    moderatedText: '',
  }),
  computed: {
    isNotModerated() {
      return this.permission.moderator && !this.item.isModerated
    },
  },
  methods: {
    async getCreativeMedia(item) {
      const {type, filename, name, duration, nativeTitle, width, height, moderationMsg} = item

      this.moderatedText = moderationMsg ?? 'service.moderation'
      if (type === 'VIDEO') {
        this.media = {
          duration,
          name,
          type,
          src: `${this.basicUrl}/file/creative/video/stream/${this.token}/${filename}`,
        }
      } else if (type === 'AUDIO') {
        this.media = {
          duration,
          name,
          type,
          src: `${this.basicUrl}/file/creative/audio/stream/${this.token}/${filename}`,
        }
      } else if (type === 'NATIVE') {
        this.media = {
          title: nativeTitle,
          name,
          type,
          width,
          height,
          src: `${this.basicUrl}/file/creative/native/image/view/${this.token}/${filename}`,
        }
      } else if (type === 'BANNER') {
        if (filename.includes('.html')) {
          const directory = filename.replace(/.html/g, '')
          this.media = {
            type: 'BANNER_HTML',
            name,
            width,
            height,
            src: `${this.basicUrl}/file/creative/banner/html5/view/${this.token}/${directory}/${filename}`,
          }
        } else {
          this.media = {
            type: 'BANNER_IMG',
            name,
            width,
            height,
            src: `${this.basicUrl}/file/creative/banner/img/view/${this.token}/${filename}`,
          }
        }
      } else {
        this.media = {}
      }
    },
  },
  async mounted() {
    await this.getCreativeMedia(this.item)
  },
}
</script>
