var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"d-flex flex-column align-center justify-space-between overflow-hidden pa-10",attrs:{"rounded":"10","elevation":"2","height":"100%"}},[(_vm.media.type === 'VIDEO')?[_c('video',{attrs:{"preload":"","controls":"","width":_vm.mediaWidth,"height":_vm.mediaHeight}},[_c('source',{attrs:{"src":_vm.media.src,"type":"video/mp4"}})]),_c('CreativeItemInfo',{attrs:{"media":_vm.media}})]:_vm._e(),(_vm.media.type === 'AUDIO')?_c('div',{staticClass:"d-flex flex-column align-center justify-space-between h--100 width-100 pt-10"},[_c('audio',{attrs:{"controls":"","src":_vm.media.src}}),_c('CreativeItemInfo',{attrs:{"media":_vm.media}})],1):_vm._e(),(_vm.media.type === 'NATIVE')?[_c('p',{staticClass:"align-self-start"},[_vm._v(_vm._s(_vm.media.title))]),_c('v-img',{attrs:{"width":_vm.mediaWidth,"height":_vm.mediaHeight,"contain":"","src":_vm.media.src}}),_c('CreativeItemInfo',{attrs:{"media":_vm.media}})]:(_vm.media.type === 'BANNER_IMG')?[_c('v-img',{attrs:{"width":_vm.mediaWidth,"height":_vm.mediaHeight,"contain":"","src":_vm.media.src}}),_c('CreativeItemInfo',{attrs:{"media":_vm.media}})]:(_vm.media.type === 'BANNER_HTML')?[_c('iframe',{attrs:{"frameborder":"0","width":_vm.mediaWidth,"height":_vm.mediaHeight,"src":_vm.media.src}}),_c('CreativeItemInfo',{attrs:{"media":_vm.media}})]:_vm._e(),_c('div',{staticClass:"text-center mt-5"},[(_vm.permission.remove)?_c('v-hover',{staticClass:"mx-5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'red' : '',"size":"20"},on:{"click":function($event){return _vm.$emit('action', {item: _vm.item, type: 'delete'})}}},[_vm._v(" mdi-trash-can-outline ")])]}}],null,false,2064917172)}):_vm._e(),(_vm.permission.read)?_c('v-hover',{staticClass:"mx-5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'red' : '',"size":"20"},on:{"click":function($event){return _vm.$emit('action', {item: _vm.item, type: 'details'})}}},[_vm._v(" mdi-pencil ")])]}}],null,false,2032890690)}):_vm._e(),(_vm.isNotModerated)?_c('span',{staticClass:"mx-5"},[_c('v-hover',{staticClass:"mr-10",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'red' : '',"size":"20"},on:{"click":function($event){return _vm.$emit('action', {item: _vm.item, type: 'moderated'})}}},[_vm._v(" mdi-credit-card-edit ")])]}}],null,false,1386541213)}),_c('TooltipEffect',{attrs:{"bottom":"","color":"red","message":_vm.moderatedText},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"red"}},on),[_vm._v("mdi-information-outline")])]}}],null,false,3339550523)})],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }